.form {
  position: absolute;
  z-index: 10;
  top: 50px;
  right: 50px;
  width: 100%;
  max-width: 300px;
  padding: 20px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  background-color: rgba(247, 247, 247, 0.9);
  p {
    text-align: left;
  }
  div {
    width: 100%;
  }
}
