.home__section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  height: auto;
  img {
    margin-top: 50px;
  }
  p {
    max-width: 375px;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
  }
}

.human {
  max-width: 400px;
  width: 100%;
  opacity: 0.7;
  transition: opacity 0.3s ease-in-out;
  &:hover {
    opacity: 1;
    cursor: pointer;
    transition: opacity 0.5s ease-in-out;
  }
}
