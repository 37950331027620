.nav {
  width: 73px;
  height: 100vh;
  background: #091d3a;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 20px;
}
.item {
  opacity: 0.25;
  transition: all 0.5s ease-in-out;
  display: block;
  padding: 10px;
  &:hover {
    opacity: 1;
    transition: all 0.2s ease-in-out;
  }
}
